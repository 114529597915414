.LoaderV2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoaderV2.is-spacy {
  min-height: 40vh;
  width: 100%;
}
.LoaderV2-spinner {
  font-size: 25px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}
.LoaderV2-blade {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 2px;
  height: 5px;
  border-radius: 2px;
  background-color: transparent;
  transform-origin: center -0.2222em;
  animation: spinner-fade 1s infinite linear;
}
.LoaderV2.is-inline {
  display: inline-block;
}
.LoaderV2-spinner {
  font-size: 22px;
}
.LoaderV2-blade {
  width: 2px;
  border-radius: 1px;
}
.LoaderV2-blade:nth-child(1) {
  animation-delay: 0s;
  transform: rotate(0deg);
}
.LoaderV2-blade:nth-child(2) {
  animation-delay: 0.083s;
  transform: rotate(30deg);
}
.LoaderV2-blade:nth-child(3) {
  animation-delay: 0.166s;
  transform: rotate(60deg);
}
.LoaderV2-blade:nth-child(4) {
  animation-delay: 0.249s;
  transform: rotate(90deg);
}
.LoaderV2-blade:nth-child(5) {
  animation-delay: 0.332s;
  transform: rotate(120deg);
}
.LoaderV2-blade:nth-child(6) {
  animation-delay: 0.415s;
  transform: rotate(150deg);
}
.LoaderV2-blade:nth-child(7) {
  animation-delay: 0.498s;
  transform: rotate(180deg);
}
.LoaderV2-blade:nth-child(8) {
  animation-delay: 0.581s;
  transform: rotate(210deg);
}
.LoaderV2-blade:nth-child(9) {
  animation-delay: 0.664s;
  transform: rotate(240deg);
}
.LoaderV2-blade:nth-child(10) {
  animation-delay: 0.747s;
  transform: rotate(270deg);
}
.LoaderV2-blade:nth-child(11) {
  animation-delay: 0.83s;
  transform: rotate(300deg);
}
.LoaderV2-blade:nth-child(12) {
  animation-delay: 0.913s;
  transform: rotate(330deg);
}
@-webkit-keyframes spinner-fade {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
@-moz-keyframes spinner-fade {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
@-webkit-keyframes spinner-fade {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
@-o-keyframes spinner-fade {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes spinner-fade {
  0% {
    background-color: #69717d;
  }
  100% {
    background-color: transparent;
  }
}
