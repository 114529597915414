.SiteHeader {
  color: #fff;
  background-image: linear-gradient(160deg, #0b151c 0%, #0d324e 100%);
  position: relative;
  overflow: hidden;
}
.SiteHeader a {
  color: inherit;
}
.SiteHeader a:hover {
  text-decoration: none;
  color: inherit;
}
.SiteHeader_content {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-row: 100%;
  grid-gap: 1rem;
}
@media (min-width: 700px) {
  .SiteHeader_content {
    padding: 2rem 1rem 1rem;
    grid-template-columns: 50% auto;
    grid-template-row: 50% 50%;
    grid-gap: 3rem;
  }
}
.SiteHeader_brand {
  display: flex;
  align-items: center;
  align-self: center;
  cursor: default;
}
.SiteHeader_brand_logo {
  filter: invert(1);
}
.SiteHeader_brand_name {
  margin-left: 15px;
  font-weight: 600;
  font-size: 16px;
}
.SiteHeader_service {
  font-size: 15px;
  display: flex;
  align-self: center;
  align-items: center;
}
@media (min-width: 700px) {
  .SiteHeader_service {
    justify-content: flex-end;
  }
}
.SiteHeader_service a,
.SiteHeader_service select {
  opacity: 0.6;
  transition: 100ms;
}
.SiteHeader_service a:hover,
.SiteHeader_service select:hover,
.SiteHeader_service a:focus,
.SiteHeader_service select:focus {
  opacity: 1;
}
.SiteHeader_service a:active,
.SiteHeader_service select:active {
  opacity: 0.8;
}
.SiteHeader_service > span {
  margin-right: 1.5rem;
}
.SiteHeader_service > span:last-child {
  margin-right: 0;
}
.SiteHeader_actions {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}
@media (min-width: 700px) {
  .SiteHeader_summary {
    grid-column: span 2;
  }
}
.SiteHeader_summary > section {
  align-self: center;
  display: flex;
  flex-flow: column;
  z-index: 1;
}
@media (min-width: 700px) {
  .SiteHeader_summary > section {
    flex-flow: row wrap;
  }
}
.SiteHeader_bg {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  user-select: none;
}
@media (max-width: 900px) {
  .SiteHeader_bg {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    right: -20%;
  }
}
.CurrencySelect {
  background: transparent;
  border: 0;
  padding: 0;
  width: auto;
  font: inherit;
  color: inherit;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.InvestmentSummary {
  flex: 1;
  padding: 0.8rem 0.5rem 0.8rem 1.4rem;
  margin: 0.5rem 0;
  white-space: nowrap;
  border-radius: 3px;
  background: rgba(31,57,77,0.4);
}
@media (min-width: 700px) {
  .InvestmentSummary {
    margin-right: 1rem;
  }
}
.InvestmentSummary .label {
  display: block;
  color: rgba(255,255,255,0.7);
  margin-bottom: 9px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 20px;
}
.InvestmentSummary .content {
  display: flex;
  align-items: center;
}
.InvestmentSummary .Money {
  font-size: 24px;
  margin-right: 1rem;
}
.InvestmentSummary .Money.with-colors.is-positive {
  color: #2dbf8e;
}
.InvestmentSummary .Money::after {
  color: rgba(255,255,255,0.5);
}
.InvestmentSummary > *:last-child {
  margin-right: 0;
}
