.Loader {
  position: relative;
  width: 32px;
  height: 32px;
  opacity: 0;
  animation: fadeInLoader 200ms ease-in forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.Loader.is-fixed {
  position: fixed;
  top: 40vh;
  left: 50vw;
  z-index: 1500;
}
.Loader.is-spacy {
  min-height: 40vh;
  width: 100%;
}
.react-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  top: 0;
  left: -6px;
}
.react-spinner_bar {
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: #000;
  position: absolute;
  width: 6px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform-origin: 30% 50%;
}
.react-spinner-text {
  display: block;
  position: relative;
  margin-top: 25px;
  font-size: 15px;
  font-weight: 400;
  color: #969696;
}
@-moz-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@-webkit-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@-o-keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@keyframes react-spinner_spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@-moz-keyframes fadeInLoader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInLoader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeInLoader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInLoader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
