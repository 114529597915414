.Percentage.is-boxed {
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 13px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
}
.Percentage.is-boxed.is-positive {
  background: #27c3b0;
}
.Percentage.is-boxed.is-negative {
  background: #fd6161;
}
.Percentage.with-sign.is-positive::before {
  content: '+';
}
.Percentage.with-sign.is-negative::before {
  content: '-';
}
