.dd__wrapper {
  position: relative;
}
.dd__selectControl {
  font-size: 16px;
  background: #fcfcfc;
  border-radius: 3px;
  color: currentColor;
  display: flex;
  align-items: baseline;
  padding: 9px 14px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
}
.dd__opened .dd__selectControl {
  border-color: #b4b4b4;
  border-bottom-color: #d3d3d3;
  border-radius: 3px 3px 0 0;
}
.dd__selected {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;
}
.dd__placeholder {
  color: #757575;
}
.dd__list {
  display: none;
  border: 1px solid #d3d3d3;
  border-top: 0;
  position: absolute !important;
  background-color: #fff;
  overflow: auto;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  border-radius: 3px;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.1);
  will-change: transform;
}
.dd__opened .dd__list {
  display: block;
  border-radius: 0 0 3px 3px;
}
.dd__list:empty {
  display: none;
}
.dd__option {
  padding: 0 12px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  height: 36px;
  align-items: center;
}
.dd__option:hover {
  background-color: #efefef;
}
.dd__selectedOption {
  background-color: #efefef;
}
.dd__search {
  display: inline-block;
  align-items: center;
  flex-grow: 1;
  border: 1px solid transparent;
  outline: 0;
  opacity: 0;
  min-height: 20px;
  word-break: break-word;
}
.dd__multi .dd__search {
  margin-top: 2px;
}
.dd__opened .dd__search {
  opacity: 1;
}
