.Alert {
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
  line-height: 1.4;
}
.Alert.is-error {
  border-left: 2px solid #ff4356;
  background: #fff0f1;
}
.ContentPage {
  padding: 2rem;
}
.ContentPage h1 {
  font-size: 32px;
}
.ContentPage h2 {
  font-size: 22px;
}
.ContentPage .page-title {
  margin-bottom: 2rem;
  font-size: 32px;
}
.ContentPage .page-title + .lead {
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}
.ContentPage .lead {
  color: #535353;
  font-size: 16px;
  line-height: 24px;
}
.ContentPage .form-group {
  margin-bottom: 1rem;
}
.ContentPage .form-group label {
  margin-bottom: 0.3rem;
  display: block;
}
.ContentPage .button {
  background: #27c3b0;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.08);
  border-radius: 3px;
  color: #fff;
  line-height: 20px;
  height: 40px;
  line-height: 40px;
  padding: 0 1rem;
  display: inline-block;
  transition: all 0.2s;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
  border: 0;
  cursor: pointer;
}
.ContentPage .button:hover {
  background: #30d6c1;
  text-decoration: none;
  color: #fff;
}
.ContentPage .button:active {
  background: #27c3b0;
  transform: translateY(1px);
}
.ContentPage .container {
  max-width: 1200px;
  margin: 0 auto;
}
.ContentPage .narrow-container {
  max-width: 500px;
  margin: 2rem auto;
}
.ContentPage .header {
  margin-bottom: 5vh;
}
.ContentPage .brand {
  display: flex;
  align-items: center;
  align-self: center;
  color: inherit;
}
.ContentPage .brand:hover {
  color: inherit;
  text-decoration: none;
}
.ContentPage .brand .name {
  margin-left: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 16px;
}
.ContentPage a {
  color: #27c3b0;
  transition: color 0.2s;
}
.ContentPage a:hover {
  color: #1f9c8d;
}
.ContentPage p {
  margin-bottom: 1rem;
}
.ContentPage .email-form-header {
  margin: 2rem 0 1rem;
}
.ContentPage .LoginButtons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.ContentPage .LoginButtons .SocialLoginButton {
  margin: 0.5rem;
}
.ContentPage .SocialLoginButton {
  height: 40px;
  display: inline-flex;
  padding: 5px 15px 5px 10px;
  align-items: center;
  border-radius: 4px;
  color: #fff !important;
  white-space: nowrap;
  transition: background-color 0.2s;
  font-weight: 500;
  font-size: 16px;
}
.ContentPage .SocialLoginButton:hover {
  text-decoration: none;
}
.ContentPage .SocialLoginButton.is-google {
  background-color: #dc4e41;
}
.ContentPage .SocialLoginButton.is-google:hover {
  background-color: #d83829;
}
.ContentPage .SocialLoginButton.is-facebook {
  background-color: #4267b2;
}
.ContentPage .SocialLoginButton.is-facebook:hover {
  background-color: #3b5da0;
}
.ContentPage .SocialLoginButton-icon {
  margin-right: 15px;
}
