html {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 10px;
  line-height: 1.5;
  font-weight: 500;
}
h2 {
  font-size: 24px;
  font-weight: 400;
}
h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
h4 {
  font-size: 15px;
  font-weight: 500;
}
strong {
  font-weight: 500;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
p {
  margin: 0 0 1rem;
}
a {
  color: #072233;
}
a:hover {
  color: #061b29;
  text-decoration: underline;
  text-decoration-skip: ink;
}
hr {
  border: 0;
  height: 1px;
  background: #efefef;
  margin: 1.5rem 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
:focus {
  outline: 0;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
input,
textarea,
select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 14px;
  font: inherit;
  font-size: 16px;
  border: 1.2px solid #d3d3d3;
  border-radius: 3px;
  background: #fcfcfc;
}
input:focus,
textarea:focus,
select:focus {
  border-color: #b4b4b4;
}
select {
  height: 40px;
}
textarea {
  min-height: 75px;
}
.container {
  max-width: 800px;
  margin: 2rem auto;
}
.flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .hide-small {
    display: none;
  }
}
.label {
  color: #808080;
  font-size: 12px;
  display: inline-block;
  margin: 0 0 0.5em;
}
.Summary {
  display: flex;
  margin: 5vh 1rem;
  align-items: center;
  justify-content: center;
}
.Summary_earnings {
  font-size: 2.5rem;
  font-weight: 400;
}
@media (min-width: 600px) {
  .Summary_earnings {
    font-size: 4rem;
  }
}
.AssetLine {
  display: flex;
  align-items: flex-start;
  color: inherit;
  margin: 5px 0;
  padding: 3px;
  font-size: 15px;
}
.AssetLine:hover {
  background-color: #f3f3f9;
}
.AssetLine span {
  flex: 1 0 0;
}
.FormError {
  display: inline-block;
  color: #f00;
}
.buy-price-tooltip span {
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
}
.Investments {
  margin: 1rem auto;
  padding: 1rem;
  max-width: 900px;
}
.CoinAssets_table {
  width: 100%;
  border-collapse: collapse;
  margin: 0.5rem 0 1rem;
  font-size: 14px;
}
.CoinAssets_table th,
.CoinAssets_table td {
  padding: 14px 16px;
}
.CoinAssets_table th {
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.CoinAssets_table td {
  border-bottom: 1px solid #fafafa;
  transition: background 200ms;
}
.CoinAssets_table tbody tr:nth-child(even) td {
  background: #fafafa;
}
.CoinAssets_table tbody tr .edit,
.CoinAssets_table tbody tr .delete {
  opacity: 0.5;
  padding: 10px;
  margin: -10px 0;
  display: inline-block;
  transition: opacity 0.2s;
}
.CoinAssets_table tbody tr .edit:hover,
.CoinAssets_table tbody tr .delete:hover {
  opacity: 1;
}
.CoinAssets_table tbody tr .edit:active,
.CoinAssets_table tbody tr .delete:active {
  transform: translateY(1px);
}
@media (max-width: 700px) {
  .CoinAssets_table table,
  .CoinAssets_table thead,
  .CoinAssets_table tbody,
  .CoinAssets_table th,
  .CoinAssets_table td,
  .CoinAssets_table tr {
    display: block;
  }
  .CoinAssets_table thead tr {
    display: none;
  }
  .CoinAssets_table tr {
    border: 1px solid #f0f0f0;
    margin-bottom: 2rem;
  }
  .CoinAssets_table td {
    background: none !important;
    display: flex;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    white-space: normal;
    text-align: left;
  }
  .CoinAssets_table td:last-child {
    border-bottom: 0;
  }
  .CoinAssets_table td::before {
    content: attr(data-title);
    width: 30%;
    min-width: 90px;
    text-align: right;
    padding-right: 1.5rem;
    font-weight: 600;
  }
}
.Select:hover .Select-control,
.Select.is-open .Select-control,
.Select.is-focused .Select-control {
  background-color: rgba(0,0,0,0.9) !important;
}
.Select-control {
  height: 34px !important;
  color: #fff;
  border: 0 !important;
  background-color: rgba(0,0,0,0.2) !important;
  transition: background-color 300ms;
}
.Select-value {
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}
.Select-input {
  color: #fff;
}
.Select-option {
  background-color: inherit !important;
  color: inherit !important;
  padding: 4px 12px !important;
  transition: background-color 150ms;
}
.Select-option:hover {
  background-color: #282828 !important;
}
.Select-menu-outer {
  border: 0 !important;
  border-top: 1px solid #323232 !important;
  background-color: rgba(0,0,0,0.9) !important;
}
.Select-menu {
  max-height: 145px;
}
.Select-control,
.Select-multi-value-wrapper {
  display: flex;
}
.Select-clear-zone,
.Select-arrow-zone {
  display: flex;
  align-items: center;
}
.Select-value-label {
  color: #fff !important;
}
.Modal.is-narrow .Modal-body {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}
.Modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: 400;
}
.Modal-body {
  margin: 0 auto;
  padding: 2rem;
}
@media (min-width: 600px) {
  .Modal-body {
    padding-top: 10vh;
  }
}
.Modal-close {
  font-size: 40px;
  font-weight: 200;
  line-height: 1ex;
  margin-left: auto;
  transition: opacity 0.25s;
  user-select: none;
}
@media (min-width: 700px) {
  .Modal-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 60px;
  }
}
.Modal-close:hover {
  opacity: 0.7;
  text-decoration: none;
}
.Modal-close:active {
  transform: translateY(1px);
}
.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.Button {
  padding: 12px 22px;
  font: inherit;
  background: #222;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  text-shadow: 0 2px rgba(0,0,0,0.05);
  transition: background 0.15s;
}
.Button:hover {
  background: #333;
}
.Button:active {
  background: #222;
  transform: translateY(1px);
}
.FormRow {
  position: relative;
  margin: 0 0 1.5rem;
}
.FormRow label {
  color: #222;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.InputWithSelect {
  display: flex;
  position: relative;
}
.InputWithSelect > :first-child {
  flex: 1;
}
.AssetForm .PricePerCoin {
  position: absolute;
  top: 0;
  right: 0;
}
.Box {
  background: #fff;
  border: 1px solid #edeff0;
  box-shadow: 0 2px 4px 0 rgba(229,229,229,0.5);
  border-radius: 3px;
  padding: 12px;
}
.ValueBox {
  padding: 1rem;
  border-radius: 3px;
  background: #fafafa;
}
.ValueBox .label {
  color: #888;
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1;
}
.ValueBox .value {
  font-size: 18px;
  font-weight: 500;
}
.CoinInvestment {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #f0f0f0;
}
.CoinInvestment .title {
  font-size: 20px;
  font-weight: 600;
}
.CoinInvestment .ValueBox .value {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.CoinInvestment .ValueBox .value .Money {
  margin-right: 1rem;
}
.CoinInvestment .ValueBox .value .Percentage {
  margin-left: auto;
}
.CoinLogo .img {
  display: block;
}
.CoinLogo.has-error {
  background: #efefef;
  border-radius: 50%;
}
.CoinLogo.has-error .img {
  opacity: 0;
}
.Muted {
  color: #7a7a7a;
}
.FeedbackLink {
  display: none;
  position: fixed;
  bottom: -2px;
  right: 2%;
  font-weight: 500;
  font-size: 15px;
  padding: 5px 15px 10px 15px;
  background: #00cbb3;
  color: #fff;
  border-radius: 3px 3px 0 0;
  transition: all 0.2s;
}
@media (min-width: 700px) {
  .FeedbackLink {
    display: block;
  }
}
.FeedbackLink:hover {
  transform: translateY(-2px);
  text-decoration: none;
  color: #fff;
}
.FeedbackLink:active {
  background: #00b7a1;
}
.SlideIn {
  transform: translateX(100%);
}
.SlideIn_active {
  transform: translateX(0);
  transition: transform 450ms cubic-bezier(0.19, 1, 0.22, 1);
}
.SlideOut {
  transform: translateX(0);
}
.SlideOut_active {
  transform: translateX(100%);
  transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1);
}
.FadeIn {
  opacity: 0.0001;
}
.FadeIn_active {
  opacity: 1;
  transition: 400ms opacity ease;
}
.FadeOut {
  opacity: 1;
}
.FadeOut_active {
  opacity: 0.0001;
  transition: opacity 300ms 100ms ease;
}
