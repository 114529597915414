.Money {
  position: relative;
  display: inline-block;
}
.Money::before,
.Money::after {
  font-weight: 400;
}
.Money.with-symbol {
  display: inline-flex;
  align-items: center;
}
.Money.with-symbol::after {
  content: ' ' attr(data-symbol);
  margin-left: 0.15em;
  margin-right: 0.05em;
  font-weight: 400;
  color: #656565;
}
.Money.with-colors.is-positive {
  color: #27c3b0;
}
.Money.with-colors.is-negative {
  color: #fd6161;
}
