.FrontPage {
  margin: 0 auto;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 0;
}
@media (min-width: 700px) {
  .FrontPage .content {
    margin-top: 15vmin;
    padding-right: 5vmin;
    width: 50vw;
  }
  .FrontPage .content > * {
    max-width: 450px;
  }
}
.FrontPage .content h1 {
  font-size: 32px;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.35;
}
.FrontPage .c2a {
  margin-bottom: 1rem;
}
@media (min-width: 700px) {
  .FrontPage .c2a {
    margin-top: 5vh;
  }
}
.FrontPage .signup {
  margin-top: 5vh;
}
@media (min-width: 700px) {
  .FrontPage .main {
    display: flex;
    flex-flow: row;
  }
}
@media (min-width: 700px) {
  .FrontPage .browser {
    border-radius: 5px;
    position: relative;
  }
  .FrontPage .browser::before {
    content: '';
    height: 40px;
    display: block;
    background: #efefef;
    border-radius: 5px 5px 0 0;
  }
  .FrontPage .browser::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
  }
}
.FrontPage .muted {
  color: #707070;
}
.FrontPage .preview {
  position: relative;
}
@media (min-width: 700px) {
  .FrontPage .preview {
    width: 50vw;
  }
}
@media (max-width: 700px) {
  .FrontPage .preview {
    margin: 2rem -2rem;
    max-height: 400px;
    overflow: hidden;
  }
}
@media (min-width: 700px) {
  .FrontPage .preview .screenshot {
    display: flex;
    flex: 1;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: -10vw;
    min-height: 100vh;
    padding: 3rem 0 0 3rem;
    background-image: linear-gradient(-135deg, #d8ffed 0%, #83acff 100%);
  }
}
.FrontPage .preview .screenshot .img {
  background-image: url("https://i.imgur.com/FuTevHh.png");
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 300px;
}
@media (min-width: 700px) {
  .FrontPage .preview .screenshot .img {
    min-height: 400px;
  }
}
@media (max-width: 700px) {
  .FrontPage .preview .screenshot .img {
    width: 200%;
    height: 400px;
    margin-left: -30px;
  }
}
.FrontPage .dots {
  display: none;
  height: 10vh;
}
@media (min-width: 700px) {
  .FrontPage .dots {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media (max-height: 600px) {
  .FrontPage .dots {
    display: none;
  }
}
